<template>
    <div>
        hello world
    </div>
</template>

<script>
    export default {
        components:{
            
        },
        data(){
            return {

            }
        }, 
        methods:{
        }
    }
</script>
